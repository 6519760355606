<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <tabs ref="tabs" @selectedIndex="tabSelectedIndex">
        <tab :title="data.codeNm" :key="data.codeValue" v-for="data in xmlTypeList">
          <esp-dx-data-grid :ref="getDataGridRef(data.codeValue)" :data-grid="getDataGrid(data.codeValue)" />
        </tab>
      </tabs>
    </div>
    <DxPopup :isOpen="modal.isOpened" :option="modal.option" @saveModal="handleClickSaveXmlData" @closeModal="handleToggleXmlModal(false)">
      <template #content>
        <XmlEditor ref="xmlEditor" :options="modal.editorOption" :history="modal.history" />
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import DxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update';
  import { getResData, isSuccess } from '@/plugins/common-lib';
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;
  export default {
    components: {
      EspDxDataGrid,
      Tabs,
      Tab,
      DxPopup,
      XmlEditor,
    },
    props: {},
    watch: {},
    data() {
      return {
        dataGrid: {},
        xmlTypeList: [],
        selectedXmlType: 'wizard-template',
        modal: {
          isOpened: false,
          selectedName: null, // 'name' attribute of sql tag
          selectedDescription: null, // 'description' attribute of sql tag
          selectedSolution: null, // 'solution' attribute of sql tag
          selectedSubPath: null, // 'subPath' attribute of sql tag
          history: null, // sql 히스토리
          option: {
            title: 'XML 보고서 등록',
            width: '80%',
            height: '90%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            type: 'MASTER',
            masterNm: '', // name
            reportNm: '', // ctiType
            reportId: undefined, // historyId
            rsWidth: '30%',
            useCreateCols: true,
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
      };
    },
    computed: {},
    methods: {
      getDataGrid(codeValue) {
        return this.dataGrid[`dataGrid-${codeValue}`];
      },
      getDataGridRef(codeValue) {
        return `dataGrid-${codeValue}`;
      },
      /** @description: 선택된 탭 index */
      tabSelectedIndex() {
        this.selectDataList();
      },
      getCurrentGridRef() {
        this.selectedXmlType = this.xmlTypeList[this.$refs['tabs'].selectedIndex].codeValue;
        return this.getDataGridRef(this.selectedXmlType);
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-regDt') {
        const currentGridRef = this.getCurrentGridRef();
        this.dataGrid[currentGridRef].dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            if (!params.sort) params.sort = sort;
            params = { ...params, ...{ type: vm.selectedXmlType } };
            const payload = {
              actionname: 'MASTER_QUERY_LIST',
              data: params,
              loading: false,
            };

            const res = await vm.CALL_REPORT_API(payload);
            if (isSuccess(res)) {
              const totalCount = res.data.header.totalCount;
              vm.$refs[currentGridRef].totalCount = totalCount;
              return {
                data: res.data.data,
                totalCount: totalCount,
              };
            }
          },
          //이 친구들 없으면 에러 발생...
          insert() {},
          update() {},
          totalCount: opts => {
            //데이터 없는 경우 totalCount 에러 발생하여 추가
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /** @description: 모달 열기/닫기 */
      handleToggleXmlModal(isOpen = true, targetData = null) {
        this.setModalOption(targetData);
        this.modal.isOpened = isOpen;
      },
      setEditorOption(key, value) {
        this.modal.editorOption[key] = value;
      },
      async setModalOption(targetData) {
        // 1. title 클릭 시에는 targetData 셋팅 O -> 수정
        // 2. 등록 버튼 클릭 시에는 targetData 셋팅 X -> 등록
        this.modal.option.title = `XML 보고서 ${targetData ? '수정' : '등록'}`;
        if (targetData) {
          const { id, solution, name, subPath, description } = targetData;
          this.modal.selectedName = name;
          this.modal.selectedSolution = solution;
          this.modal.selectedSubPath = subPath;
          this.modal.selectedDescription = description;
          this.setEditorOption('reportId', this.selectedXmlType);
          this.setEditorOption('reportNm', description);
          this.setEditorOption('masterNm', name);
          this.setEditorOption('useRight', true); // 히스토리 및 설명
          const sql = await this.asyncGetSqlByNameAndSolution(name, solution, subPath);
          await this.fetchXMLFileHistoryById(id);
          this.$refs.xmlEditor.setCmEditor(sql);
        } else if (targetData === null) {
          this.setEditorOption('reportId', undefined);
          this.setEditorOption('reportNm', '');
          this.setEditorOption('useRight', false); // 히스토리 및 설명
          this.$refs.xmlEditor.clearVueData();
        }
      },
      async asyncGetSqlByNameAndSolution(name, solution, subPath) {
        const params = { name, solution, type: this.selectedXmlType, subPath };
        const payload = {
          actionname: 'MASTER_QUERY_SQL_SELECT',
          data: { ...params },
          loading: true,
        };

        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          return getResData(res).at(0).sql;
        }
      },
      async fetchXMLFileHistoryById(id) {
        const res = await this.CALL_REPORT_API({
          actionname: 'MASTER_QUERY_SQL_HISTORY',
          data: { id: id },
          loading: true,
        });

        if (isSuccess(res)) {
          this.modal.history = getResData(res);
        }
      },
      async handleClickSaveXmlData() {
        // 초깃값 '등록'
        let confirmMsg = '신규 마스터 쿼리가 추가되었습니다.';
        let actionname = 'MASTER_QUERY_SQL_INSERT';

        // Param
        const { cmEditor: sqlXml } = this.$refs.xmlEditor;
        const params = { sqlXml, useSqlPass: false, type: this.selectedXmlType };
        const sqlName = this.modal.editorOption.reportNm;
        if (sqlName !== '') {
          // Update 시
          // parameter
          params.name = this.modal.selectedName;
          params.solution = this.modal.selectedSolution;
          params.subPath = this.modal.selectedSubPath;
          params.description = this.modal.selectedDescription;
          // actionName & responseMessage
          actionname = 'MASTER_QUERY_SQL_UPDATE';
        }

        const res = await this.CALL_REPORT_API({
          actionname,
          data: params,
          loading: true,
          useErrorPopup: false,
        });

        const { resCode, resMsg } = res.data.header;
        confirmMsg = this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {
          defaultValue: '정상적으로 저장되었습니다.',
        });
        if (isSuccess(res) && (await this.$_Msg(confirmMsg))) {
          this.saveXmlModal();
        } else if (resCode !== 'XML012') {
          this.$_Msg(resMsg);
        } else if (resCode === 'XML012' && (await this.$_Confirm(`${resMsg} 강제로 저장시키겠습니까?`))) {
          const forceRes = await this.CALL_REPORT_API({
            actionname: actionname,
            data: { ...params, useSqlPass: true },
            loading: true,
          });

          if (isSuccess(forceRes) && (await this.$_Msg(confirmMsg))) {
            this.saveXmlModal();
          }
        }
      },
      saveXmlModal() {
        this.handleToggleXmlModal(false);
        this.$refs[this.getCurrentGridRef()][0].refreshData();
        this.$refs.xmlEditor.clearVueData();
      },
      async asyncDeleteSqlInMasterQuery() {
        const gridRef = this.$refs[this.getCurrentGridRef()][0];
        const instance = gridRef.getGridInstance;
        const names = instance.getSelectedRowKeys();
        const items = instance
          .getSelectedRowsData()
          .map(obj => ({ name: obj.name, solution: obj.solution, subPath: obj.subPath, type: obj.type, id: obj.id }));
        if (names.length === 0) {
          const notSelectedMsg = this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {
            defaultValue: '대상이 선택되어 있지 않습니다.',
          });
          await this.$_Msg(notSelectedMsg);
          return;
        }

        const isDeleteMsg = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', {
          defaultValue: '선택한 데이터를 삭제하시겠습니까?',
        });
        if (await this.$_Confirm(isDeleteMsg)) {
          const params = { data: { type: this.selectedXmlType, items: items } };
          const actionname = 'MASTER_QUERY_SQL_DELETE';
          const res = await this.CALL_REPORT_API({
            actionname,
            data: params,
            loading: true,
          });

          if (isSuccess(res)) {
            const successDeleteMsg = this.$_msgContents('COMMON.MESSAGE.CMN_SUC_DELETE', {
              defaultValue: '정상적으로 삭제되었습니다.',
            });
            if (await this.$_Msg(successDeleteMsg)) {
              gridRef.refreshData();
            }
          }
        }
      },
      setVm() {
        vm = this;
      },
      createdGridData() {
        this.xmlTypeList = this.$_getCode('report_xml_type');
        this.selectedXmlType = this.xmlTypeList.at(0)?.codeValue;
        this.xmlTypeList.forEach(v => {
          this.$set(this.dataGrid, `dataGrid-${v.codeValue}`, {
            refName: `dataGrid-${v.codeValue}`,
            allowColumnResizing: true, //컬럼 사이즈 허용
            showBorders: false, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            dataSource: [],
            // width:'200',     // 주석처리시 100%
            height: '525', // 주석처리시 100%
            apiActionNm: {},
            showActionButtons: {
              select: false,
              copy: false,
              delete: false,
              customButtons: [
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '등록',
                    elementAttr: { class: 'btn_XS default filled' },
                    width: 60,
                    height: 30,
                    onClick() {
                      vm.handleToggleXmlModal(true);
                    },
                  },
                  location: 'before',
                },
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '삭제',
                    elementAttr: { class: 'btn_XS white light_filled trash' },
                    width: 60,
                    height: 30,
                    onClick() {
                      vm.asyncDeleteSqlInMasterQuery();
                    },
                  },
                  location: 'before',
                },
              ],
            },
            grouping: {
              contextMenuEnabled: false,
              autoExpandAll: false,
              allowCollapsing: true,
              expandMode: 'rowClick', // rowClick or buttonClick
            },
            groupPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: true, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'multiple', // single multiple
            },
            remoteOperations: {
              // 서버사이드 여부
              filtering: false,
              sorting: false,
              grouping: false,
              paging: false,
            },
            paging: {
              // scrolling 미사용시만 적용됨
              enabled: true,
              pageSize: 10,
              pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: true, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: true,
            },
            headerFilter: {
              visible: true,
            },
            editing: {
              allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
              allowDeleting: false,
              allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
              mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
              startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            columns: [
              {
                caption: 'ID',
                dataField: 'id',
                height: 10,
                alignment: 'center',
                visible: false,
                allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                allowExporing: true,
              },
              {
                caption: '이름',
                dataField: 'name',
                height: 40,
                alignment: 'left',
                visible: true,
                allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                allowExporing: true,
                cellTemplate: (container, item) => {
                  let aTag = document.createElement('a');
                  aTag.innerText = item.value;
                  aTag.addEventListener('click', () => {
                    vm.handleToggleXmlModal(true, item.data);
                  });
                  container.append(aTag);
                },
              },
              {
                caption: '테이블 유형',
                dataField: 'tableType',
                height: 40,
                alignment: 'left',
                visible: true,
                allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                allowExporing: true,
                cellTemplate: (container, item) => {
                  container.append(item.value.toUpperCase());
                },
              },
              {
                caption: '설명',
                dataField: 'description',
                height: 40,
                alignment: 'left', // left center right
                visible: true,
                allowEditing: false,
                sortOrder: 'none', // acs desc none
                allowHeaderFiltering: false,
                allowExporing: true,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'left', // left or right
              },
              {
                caption: '솔루션 유형',
                dataField: 'solution',
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                allowExporing: true,
                cellTemplate: (container, item) => {
                  container.append(item.value.toUpperCase());
                },
              },
              {
                caption: '서브 유형',
                dataField: 'subPath',
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                allowExporing: true,
                cellTemplate: (container, item) => {
                  const uppercaseValue = item.value ? item.value.toUpperCase() : '';
                  container.append(uppercaseValue);
                },
              },
            ],
          });
        });
      },
    },
    created() {
      this.setVm();
      this.createdGridData();
    },
    mounted() {},
  };
</script>

<style scoped>
  #searchDept .title {
    display: inline-block;
    margin-right: 10px;
  }
  #searchDept .dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 4px;
  }
  #queryContainer .empty-line {
    display: block;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
</style>
